const ThumbsSwipeHelpers = {
  isBrowser: typeof window !== "undefined",
  thumbs: null,
  thumbSize: null,
  thumbSliderRef: null,
  wrapperSize: null,
  sliderWidth: null,
  visibleItems: null,
  pixelsPerSwipe: null,
  maxSwipes: null,
  maxPixelPosition: null,
  curSwipe: 0,
  galleryRef: null,

  setGalleryRef: function(ref) {
    this.galleryRef = ref
  },
  setThumbs: function(className) {
    if (!this.isBrowser) return
    this.thumbs = document.getElementsByClassName(className)
  },
  setThumbSliderRef: function(className) {
    if (!this.isBrowser) return
    this.thumbSliderRef = document.getElementsByClassName(className)[0]
  },
  setSizes: function() {
    if (!this.thumbs || !this.thumbSliderRef) {
      return false
    }

    this.thumbSize = this.outerWidth(this.thumbs[0])
    this.wrapperSize = this.thumbSliderRef.clientWidth
    this.sliderWidth = this.thumbSize * this.thumbs.length
    this.visibleItems = Math.floor(this.wrapperSize / this.thumbSize)
    this.pixelsPerSwipe = this.visibleItems * this.thumbSize
    this.maxSwipes = Math.round(
      this.sliderWidth / (this.visibleItems * this.thumbSize)
    )
    this.maxPixelPosition = this.sliderWidth - this.wrapperSize
    this.setCSSTranslate(0)
    this.curSwipe = 0
  },
  setSwipeableHandlers: function(handlers) {
    handlers.ref(this.thumbSliderRef)
  },
  handleSlideMove: function(data) {
    if (!this.thumbSize || !this.thumbSliderRef) {
      return false
    }
    this.galleryRef.pause()

    let nextSwipe = this.curSwipe

    if (data.dir === "Left") {
      if (this.curSwipe + 1 < this.maxSwipes || this.maxSwipes < 2)
        nextSwipe = this.curSwipe + 1
      else return
    } else {
      if (this.curSwipe - 1 > -1) nextSwipe = this.curSwipe - 1
      else return
    }

    let nextPixelPosition = -(this.pixelsPerSwipe * nextSwipe) // -((100 / maxSwipes) * nextSwipe)
    if (Math.abs(nextPixelPosition) > this.maxPixelPosition) {
      //thumbs[thumbs.length - 1].click()
      //return
      nextPixelPosition = -this.maxPixelPosition
    }
    this.setCSSTranslate(nextPixelPosition)
    this.curSwipe = nextSwipe

    return true
  },

  setCSSTranslate: function(position) {
    const CSSTranslate = function(position, metric, axis) {
      const positionPercent = position === 0 ? position : position + metric
      const positionCss =
        axis === "horizontal"
          ? [positionPercent, 0, 0]
          : [0, positionPercent, 0]
      const transitionProp = "translate3d"

      const translatedPosition = "(" + positionCss.join(",") + ")"
      return transitionProp + translatedPosition
    }
    ;[
      "WebkitTransform",
      "MozTransform",
      "MsTransform",
      "OTransform",
      "transform",
      "msTransform",
    ].forEach(prop => {
      //thumbSliderRef.current.dataset.currentPosition = nextPixelPosition
      this.thumbSliderRef.style[prop] = CSSTranslate(
        position,
        "px" /** or % */,
        "horizontal"
      )
    })
  },
  outerWidth: el => {
    let width = el.offsetWidth
    const style = getComputedStyle(el)

    width += parseInt(style.marginLeft) + parseInt(style.marginRight)
    return width
  },
}

export default ThumbsSwipeHelpers
